@if (vm)
{
@if (vm.section.icon || vm.section.title)
{
<div class="asc-form-title">
   @if (vm.section.icon)
   {
   <asc-form-icon [icon]="vm.section.icon" />
   }
   @if (vm.section.title)
   {
   <h3>{{vm.section.title}}</h3>
   }
</div>
}
@if (vm.section.description)
{
<p class="asc-form-description">{{vm.section.description}}</p>
}
@if (vm.groups.length > 0)
{
<div class="asc-form-groups">
   @for (group of vm.groups; track $index)
   {
   <asc-form-group [group]="group" />
   }
</div>
}
}