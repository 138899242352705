import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormViewModel } from '../../view-model/form-view-model';
import { FormSectionViewModel } from '../../view-model/form-section-view-model';
import { FormService } from '../../services/form.service';


@Component({
   selector: 'asc-form-section-navigation',
   templateUrl: './form-section-navigation.component.html',
   styleUrls: ['./form-section-navigation.component.scss'],
})
export class FormSectionNavigationComponent {
   @Input()
   public form?: FormViewModel;

   @Input()
   public allowNavigation = true;

   @Output()
   public sectionSelected = new EventEmitter<FormSectionViewModel>();

   constructor(private formService: FormService) { }

   public selectItem(index: number) {
      if (!this.allowNavigation || !this.form) { return; }

      this.formService.getNavigation(this.form)?.selectSection(index);
   }
}
