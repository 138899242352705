@if (form)
{
@for (vm of form.sections; track $index) {
<div [ngClass]="{'asc-todo': !(vm.isPassed || vm.isSelected), 'asc-passed': vm.isPassed, 'asc-selected': vm.isSelected, 'asc-invalid': vm.isTouched && !vm.isValid, 'asc-clickable': this.allowNavigation}" (click)="selectItem($index)">
   <mat-icon>{{(vm.isSelected) ? 'adjust' : (vm.isTouched && !vm.isValid) ? 'error_outline' : (vm.isPassed) ? 'check_circle_outline' : 'radio_button_unchecked'}}</mat-icon>
   <asc-form-icon [icon]="vm.section.icon" />
   <p [ngClass]="{'asc-no-icon': !vm.section.icon}">{{vm.section.title}}</p>
</div>
}
}
