import { Component, Input } from '@angular/core';
import { Icon } from '../../model/icon';


@Component({
   selector: 'asc-form-icon',
   templateUrl: './form-icon.component.html',
   styleUrls: ['./form-icon.component.scss'],
})
export class FormIconComponent {

   @Input()
   public icon?: Icon

   public get imageSource(): string | undefined {
      if (this.icon) {
         const imageExtension = (this.icon.imageExtension === 'svg') ? 'svg+xml' : this.icon.imageExtension;
         return `data:image/${imageExtension};base64,${this.icon.content}`;
      }
      else {
         return undefined;
      }
   }
}
